import { Progress } from "@material-tailwind/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  box,
  clock,
  crown,
  gmt_gold_logo,
  money,
  shield,
  shield_green,
  shield_red,
  size,
} from "../assets/icons";
import { rolex_oyester } from "../assets/images";
import Button from "../components/Button";
import Header from "../components/Header";
import SellerReviewCard from "../components/SellerReviewCard";
import SmallImage from "../components/SmallImage";
import { updateCart } from "../store/cartSlice";
import DownloadApp from "./DownloadApp";
import SellerCard from "./SellerCard";

function ProductDetail({
  brand,
  model,
  price,
  currency,
  specs,
  tags,
  seller_detail,
  about,
  video,
  rating,
  images,
}) {
  const navigation = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const authenticate = useSelector((state) => state.user);

  const watchDetail = location?.state?.watchDetail;

  const userDetail = useSelector((state) => state.user.userDetail);


  const [showDownloadApp, setShowDownloadApp] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(
    watchDetail?.images[0]?.src
  );

  const handleBackground = (newBackgoundImage) => {
    setBackgroundImage(newBackgoundImage);
  };

  return (
    <div className="bg-background800">
      <div
        className={`flex flex-col sm:flex-row w-auto h-full bg-background800 no-scrollbar overflow-y-scroll`}
      >
        <div
          className={`flex flex-col justify-start items-center sm:basis-1/2  `}
        >
          {/* Image */}
          <div
            className={
              "flex flex-col justify-start items-start relative sm:flex-row sm:w-full"
            }
          >
            <div className={`sm:order-2 sm:w-full `}>
              {/* {watchDetail?.images && */}
              <img
                src={backgroundImage}
                alt={watchDetail?.images[0]?.image_type}
                className="w-full h-[435px] sm:h-full object-cover"
              />
              {/* }  */}
            </div>
            <div
              className={`flex flex-row justify-start items-center gap-4 w-full no-scrollbar overflow-scroll pl-4 absolute bottom-3  sm:flex-col sm:order-1 sm:pl-0 sm:items-start  sm:w-fit sm:justify-start sm:top-8 sm:ml-8`}
            >
              {watchDetail?.images?.map((item, index) => {
                return (
                  <SmallImage
                    onClick={() => handleBackground(item?.src)}
                    src={item?.src}
                    name={item?.image_type}
                    width_px={"72"}
                    height_px={"72"}
                    is_border={backgroundImage === item.src}
                  />
                );
              })}
            </div>
          </div>
        </div>

        {/* Watch Info Section */}
        <div className="flex flex-col sm:basis-1/2 sm:h-screen no-scrollbar overflow-y-scroll relative">
          <div
            className={`flex flex-col justify-start items-start text-white px-4 pt-4`}
          >
            <span className="text text-white mb-1">{watchDetail?.brand}</span>
            <span className="header text-2xl text-white">
              {watchDetail?.model}
            </span>
            <span className="text text-green-200 text-sm">
              in Stock in Kuwait
            </span>

            {/* <div className='flex flex-row justify-start items-center gap-4 w-full overflow-x-scroll pt-4'>
                            {watchDetail.features.map((item, index) => {
                        <div className='flex flex-row justify-start items-center gap-4 w-full overflow-x-scroll no-scrollbar pt-4'>
                            {WatchDetails.features.map((item, index) => {
                                return <WatchFeature icon={item.icon} icon_name={item.icon_name} feature={item.feature} feature_value={item.feature_value} />
                            })}
                        </div> */}
          </div>

          {/* Seller Info Section */}
          <div className="flex flex-col justify-start pl-4 mt-6">
            <span className="title text-lg text-white">Sold By</span>
            <SellerCard
              name={watchDetail?.seller?.name}
              seller_type={watchDetail?.seller?.seller_type}
              rating={watchDetail?.seller?.rating_stats.avg}
              rating_from={watchDetail?.seller?.rating_stats.total}
              onPress={() =>
                navigation("/seller", {
                  state: { seller: watchDetail?.seller },
                })
              }
            />
          </div>

          {/* Buyer Protection */}
          <div className="flex flex-col justify-start mx-4 mt-6">
            <span className="title text-lg text-white">Buyer Protection</span>
            <div className="flex flex-col justify-center items-center gap-8 mt-6">
              <div className="flex flex-row gap-3 justify-center items-start">
                <div className="flex flex-row relative justify-center items-center basis-1/5">
                  <img src={shield} className="h-16 w-16" />
                  <img src={gmt_gold_logo} className="h-3 w-8 absolute " />
                </div>
                <div className="flex flex-col justify-center items-start basis-3/4">
                  <span className="title text-lg text-white">
                    GMT24 Authenticity
                  </span>
                  <span className="text text-sm text-whiteSecondary opacity-40">
                    Watches fully checked and inspected by GMT 24 once received
                    from merchant and before delivery to buyer.
                  </span>
                </div>
              </div>

              <div className="flex flex-row gap-3 justify-center items-start">
                <div className="flex flex-row relative justify-center items-center basis-1/5">
                  <img src={shield_green} className="h-16 w-16" />
                  <img src={money} className="h-7 w-7 absolute " />
                </div>
                <div className="flex flex-col justify-center items-start basis-3/4">
                  <span className="title text-lg text-white">Money Back</span>
                  <span className="text text-sm text-whiteSecondary opacity-40">
                    Buyer can return the watch 3 days within date of delivery,
                    with the same condition as received, in conjunction with
                    providing a comfortable and secure shopping experience to
                    our customers at GMT 24.
                  </span>
                </div>
              </div>

              <div className="flex flex-row gap-3 justify-center items-start">
                <div className="flex flex-row relative justify-center items-center basis-1/5">
                  <img src={shield_red} className="h-16 w-16" />
                  <img src={box} className="h-8 w-8 absolute " />
                </div>
                <div className="flex flex-col justify-center items-start basis-3/4">
                  <span className="title text-lg text-white">Fulfillment </span>
                  <span className="text text-sm text-whiteSecondary opacity-40">
                    Inspection, packaging and delivery of the valuable
                    timepieces through GMT24’s safe and secure delivery and
                    fulfillment services.
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* About The Watch */}
          {/* <div className='flex flex-col justify-center items-start mt-6'>
                        <span className='title text-lg text-white mx-4 mb-2'>About The Watch</span>
                        <span className='text text-sm text-whiteSecondary opacity-40 mx-4 mb-6'>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </span>
                        <div className='flex flex-col justify-center items-center relative w-full'>
                            <video className='w-full h-[459px]' controls>
                                <source src="https://st4.depositphotos.com/9625262/21294/v/600/depositphotos_212942970-stock-video-23rd-happy-anniversary-text-greeting.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div> */}

          {/* Rating Section */}
          <div className="mt-4">
            <span className="title text-lg text-white ml-4">Rating</span>

            <div className={`flex flex-col gap-4 justify-center px-4 mt-5`}>
              <div className="flex flex-row gap-4 justify-center items-center">
                <div className="w-3/12 ">
                  <span className="text text-whiteSecondary opacity-40 self-start">
                    Style
                  </span>
                </div>
                <div className="w-1/12 ">
                  <span className="title text-gold self-center">4 / 5</span>
                </div>
                <div className="w-8/12 ">
                  <Progress
                    value={watchDetail?.seller?.rating_stats?.percent["1"]}
                    color="yellow"
                    className="text-gold bg-background900"
                  />
                </div>
              </div>
              <div className="flex flex-row gap-4 justify-center items-center">
                <div className="w-3/12 ">
                  <span className="text text-whiteSecondary opacity-40 self-start">
                    Value
                  </span>
                </div>
                <div className="w-1/12 ">
                  <span className="title text-gold self-center">4 / 5</span>
                </div>
                <div className="w-8/12 ">
                  <Progress
                    value={watchDetail?.seller?.rating_stats?.percent["2"]}
                    color="yellow"
                    className="text-gold bg-background900"
                  />
                </div>
              </div>
              <div className="flex flex-row gap-4 justify-center items-center">
                <div className="w-3/12 ">
                  <span className="text text-whiteSecondary opacity-40 self-start">
                    Usage
                  </span>
                </div>
                <div className="w-1/12 ">
                  <span className="title text-gold self-center">4 / 5</span>
                </div>
                <div className="w-8/12 ">
                  <Progress
                    value={watchDetail?.seller?.rating_stats?.percent["3"]}
                    color="yellow"
                    className="text-gold bg-background900"
                  />
                </div>
              </div>
              <div className="flex flex-row gap-4 justify-center items-center">
                <div className="w-3/12 ">
                  <span className="text text-whiteSecondary opacity-40 self-start">
                    Longevity
                  </span>
                </div>
                <div className="w-1/12 ">
                  <span className="title text-gold self-center">4 / 5</span>
                </div>
                <div className="w-8/12 ">
                  <Progress
                    value={watchDetail?.seller?.rating_stats?.percent["4"]}
                    color="yellow"
                    className="text-gold bg-background900"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Seller Reviews */}
          <div className="mt-9 mb-36">
            <span className="title text-lg text-white ml-4">
              Seller Reviews
            </span>
            {watchDetail?.rating?.reviews.slice(0, 3).map((item, index) => {
              return (
                <SellerReviewCard
                  customer_image={item.customer_image}
                  name={item.name}
                  rating={item.rating}
                  review={item.review}
                />
              );
            })}
          </div>

          <div
            className="flex flex-col justify-center gap-[22px] w-full h-36 rounded-t-[25px] pt-[30px] px-4 pb-6 fixed bottom-0 sm:w-1/2"
            style={{
              background:
                "linear-gradient(136deg, #383432 5.15%, #2B2827 100%)",
            }}
          >
            <div className="flex flex-row justify-between items-center">
              <span className="text text-whiteSecondary text-xs opacity-40">
                {watchDetail?.description}
              </span>
              <div className="flex flex-row justify-center gap-2 items-end">
                <span className="text text-whiteSecondary text-sm opacity-40">
                  {watchDetail?.product_currency?.code}
                </span>
                <span className="text text-white text-2xl">
                  {watchDetail?.price}
                </span>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center gap-4">
              <Button
                text={"Negotiate Price"}
                isBorder={true}
                onClick={() => setShowDownloadApp(true)}
                width={"w-[188px] md:w-[188px]"}
                height={"h-12 md:h-[56px]"}
              />
              <Button
                text={"Add to Cart"}
                isBorder={false}
                onClick={() => {
                  if (userDetail?.status || userDetail === undefined ) {
                    navigation("/signin");
                  } else {
                    navigation("/checkout-cart");
                    dispatch(
                      updateCart({ product_id: watchDetail?.id, add_qty: 1 })
                    );
                  }
                }}
                width={"w-[188px] md:w-[188px]"}
                height={"h-12 md:h-[56px]"}
              />
            </div>
          </div>
          {/* <div className={`flex flex-col sm:flex-1`}></div>
            <div className={`flex flex-col sm:flex-1`}></div> */}
          {/* <DownloadApp open={showDownloadApp} handleShow={setShowDownloadApp} /> */}
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
