import React from "react";
import AccessoriesCard from "./AccessoriesCard";
import "swiper/css";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

const NewInAccesoriesSection = ({ products, header }) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className={`flex flex-col justify-center items-center  py-0 gap-2 my-8`}
      >
        <div
          className={`flex flex-col justify-center items-center  gap-2 sm:mb-1 mt-7`}
        >
          <span className={`text-small-green `}>New in</span>
          <span
            className={` title text-haeder tracking-[.12px] text-2xl sm:text-5xl sm:tracking-[.24px]`}
          >
            {header}
          </span>
        </div>

        <div
          className={` flex flex-row w-full sm:basis-1/2 h-auto overflow-x-scroll  relative  no-scrollbar
        `}
        >
          {products &&
            products?.map((value, index) => {
              return (
                <div className={`flex`} key={index}>
                  <AccessoriesCard
                    accessoryDetail={value}
                    onClick={() => navigate("product-detail")}
                  />
                </div>
              );
            })}
        </div>
      </div>

      <div className={`hidden sm:flex sm:justify-center`}>
        <Button
          text={"View All"}
          isBorder={false}
          // onClick={() => console.log("Download App")}
          onClick={() =>  navigate("/accessory")}
          width={"w-[145px] md:w-[145px]"}
          height={"h-[64px] md:h-[56px]"}
        />
      </div>
    </>
  );
};

export default NewInAccesoriesSection;
