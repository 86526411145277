import React from "react";
import CustomInput from "../components/CustomInput";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { updateAddress } from "../store/user/userSlice";
import LeftArrow from "../assets/icons/Arrow-left.svg";

import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  address: Yup.string().required("The Address  field is required"),
  idnumber: Yup.string().required("The CivilId Number field is required"),
  Country: Yup.string().required("The Country field is required"),
  Area: Yup.string().required("The Area field is required"),
  Block: Yup.string().required("The Block field is required"),
  Street: Yup.string().required("The Street field is required"),
  Avenue: Yup.string().required("The Avenue field is required"),
  Building: Yup.string().required("The Building field is required"),
  mobile_no: Yup.string()
    .matches(/^[+\d-]+$/, "Mobile number must contain only digits, '+' and '-'")
    .min(10, "Mobile number must be at least 10 characters")
    .max(15, "Mobile number must be at most 15 characters")
    .required("Mobile number is required"),
});
const MyAddress = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.user.userDetail);

  const addressUpdate = (values) => {
    dispatch(
      updateAddress({
        address_1: userDetail.billing.address_1,
        civil_id: userDetail.billing.civil_id,
        city: userDetail?.city,
        mobile: userDetail?.mobile,
        mobile_country_code: userDetail?.mobile_country_code,
        country_code: userDetail.billing.country_code,
        country: userDetail.billing.Country,
        civil_id_file_name: userDetail.billing.civil_id_file_name,
        civil_id_attachment: userDetail.billing.civil_id_attachment,
        email: userDetail.billing.email,
        first_name: userDetail.billing.first_name,
        last_name: userDetail.billing.last_name,
        phone: userDetail.billing.phone,
      })
    );
  };
  return (
    <Formik
      initialValues={{
        address: userDetail?.billing?.address_1,
        mobile_no: userDetail?.billing?.mobile,
        idnumber: userDetail?.billing?.civil_id,
        Country: userDetail?.billing?.country,
        Area: "",
        Block: "",
        Building: "",
        Street: "",
        Avenue: "",
      }}
      validationSchema={validationSchema}
      onSubmit={addressUpdate}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} className=" ">
          <div className="w-auto sm-w-[670px] bg-background800  ">
            <div className="flex flex-col gap-4 p-8 ">
              <div className="flex flex-row gap-2 sm:justify-start justify-between">
                <img
                  src={LeftArrow}
                  alt="icon"
                  onClick={() => Navigate("/account")}
                  className="block sm:hidden"
                />
                <span className="title sm:din-text text-white text-base sm:text-2xl ">
                  My Address
                </span>
              </div>
              <div>
                <CustomInput
                  width={" w-[295px] sm:w-[602px] "}
                  type="text"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Address Name"
                />
                <span className={`text-gold text-xs mt-1`}>
                  {errors.address && touched.address && errors.address}
                </span>
                <CustomInput
                  width={" w-[295px] sm:w-[602px] "}
                  type="tel"
                  name="mobile_no"
                  value={values.mobile_no}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Phone Number"
                />
                <span className={`text-gold text-xs mt-1`}>
                  {errors.mobile_no && touched.mobile_no && errors.mobile_no}
                </span>
                <CustomInput
                  width={" w-[295px] sm:w-[602px] "}
                  type="text"
                  value={values.idnumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="idnumber"
                  placeholder="Civil ID Number"
                />
                <span className={`text-gold text-xs mt-1`}>
                  {errors.idnumber && touched.idnumber && errors.idnumber}
                </span>
                <CustomInput
                  width={" w-[295px] sm:w-[602px] "}
                  name="Country"
                  value={values.Country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  placeholder="Country"
                />
                <span className={`text-gold text-xs mt-1`}>
                  {errors.Country && touched.Country && errors.Country}
                </span>
                <CustomInput
                  width={" w-[295px] sm:w-[602px] "}
                  name="Area"
                  value={values.Area}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  placeholder="Governorate/Area"
                />
                <span className={`text-gold text-xs mt-1`}>
                  {errors.Area && touched.Area && errors.Area}
                </span>

                <div className="flex flex-row  items-center min-w-0 gap-4 ">
                  <div className="flex flex-col min-w-0">
                    <CustomInput
                      name="Block"
                      width={" w-[142px] sm:w-[293px] "}
                      value={values.Block}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      height={`h-14 `}
                      type="text"
                      placeholder="Block"
                    />
                    <span className={`text-gold text-xs mt-1`}>
                      {errors.Block && touched.Block && errors.Block}
                    </span>
                    <CustomInput
                      name="Building"
                      width={" w-[142px] sm:w-[293px] "}
                      value={values.Building}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      height={`h-14 `}
                      type="text"
                      placeholder="Building"
                    />
                    <span className={`text-gold text-xs mt-1`}>
                      {errors.Building && touched.Building && errors.Building}
                    </span>
                  </div>
                  <div className="flex flex-col min-w-0">
                    <CustomInput
                      width={" w-[142px] sm:w-[293px] "}
                      height={`h-14 `}
                      name="Street"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Street}
                      type="text"
                      placeholder="Street"
                    />
                    <span className={`text-gold text-xs mt-1`}>
                      {errors.Street && touched.Street && errors.Street}
                    </span>
                    <CustomInput
                      width={" w-[142px] sm:w-[293px] "}
                      height={`h-14 `}
                      name="Avenue"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Avenue}
                      type="text"
                      placeholder="Avenue"
                      // className={`outline-none text-white border-opacity-10 bg-transparent border-b-[1px] text border-b-white100 focus:border-b-gold `}
                    />
                    <span className={`text-gold text-xs mt-1`}>
                      {errors.Avenue && touched.Avenue && errors.Avenue}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center sm:justify-end items-end sm:pr-8">
              {/* <Button width={"w-[219px] h-12"} text={"save"} isBorder={false} /> */}
              <button
                className=" rounded-full cursor-pointer bg-background900 text-tiny text-gold w-44 sm:w-[219px] h-10 md:h-[56px]"
                type="submit"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default MyAddress;
