import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper/modules";

import { NewWatches } from "../constants/WatchData";
import "swiper/css";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import StoreCard from "./StoreCard";

const ExploreStore = ({ products, header }) => {
  const navigate = useNavigate();
  const handleViewAll = () => {
    navigate("/store-detail");
  };
  return (
    <>
      <div
        className={`flex flex-col justify-center items-center pl-4 pt-4 gap-2 mt-16 sm:mt-[101px] sm:my-0 `}
      >
        <div
          className={`flex flex-col justify-center items-center gap-2 mt-4 sm:mb-1 sm:mt-16 `}
        >
          <span className={`text-small-green`}>Explore</span>
          <span
            className={` title text-haeder tracking-[.12px] text-2xl sm:text-5xl sm:tracking-[.24px]`}
          >
            {header}
          </span>
        </div>
        <div
          className={`flex flex-row w-full gap-4 sm:gap-9  sm:basis-1/2 h-auto overflow-x-scroll  relative  no-scrollbar`}
        >
          {products &&
            products?.map((value, index) => {
              return (
                <div key={index} className={`flex `}>
                  <StoreCard
                    watchDetail={value}
                    onClick={() => navigate("/product-detail")}
                  />
                </div>
              );
            })}
        </div>
      </div>

      <div className={`hidden sm:flex sm:justify-center`}>
        <Button
          text={"View All"}
          isBorder={false}
          onClick={handleViewAll}
          width={"w-[145px] md:w-[145px]"}
          height={"h-[64px] md:h-[56px]"}
        />
      </div>
    </>
  );
};

export default ExploreStore;
