import Header from "../components/Header";
import Hero from "../components/Hero";
import NewInWatchSection from "../components/NewInWatchSection";
import NewInWatchSection2 from "../components/NewWatchesSection2";
import NewInAccesoriesSection from "../components/NewInAccessoriesSection";
import WhyUs from "../components/WhyUs";
import Footer from "../components/Footer";
import OurApp from "../components/OurApp";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllProducts } from "../store/categorySlice";
import { useEffect } from "react";
import ExploreStore from "../components/ExploreStore";
import {
  fetchProductByCategory,
  select_best_selling,
  select_high_demand,
  select_most_viewed,
  select_recently_added,
  select_recently_sold,
} from "../store/homeSlice";
import { filterProductData } from "../store/categorySlice";
import { getRequiredData } from "../store/categorySlice";
import { fetchMyCart } from "../store/cartSlice";
import { getBannerImages } from "../store/bannerImagesSlice";
function Home() {
  const dispatch = useDispatch();
  const filteredProducts = useSelector(filterProductData);
  const high_demand = useSelector(select_high_demand);
  const recently_added = useSelector(select_recently_added);

  const most_viewed = useSelector(select_most_viewed);
  const recently_sold = useSelector(select_recently_sold);
  const best_selling = useSelector(select_best_selling);
  const cart = useSelector((state) => state.cart);
  const bannerImages = useSelector((state) => state.bannerImages);

  useEffect(() => {
    if (high_demand?.status === "idle") {
      dispatch(
        fetchProductByCategory({
          categoryId: "High Demand",
          per_page: 10,
          page: 1,
          filter: "",
          name: "",
          isShowAll: false,
          isShowSold: false,
        })
      );
    }
  }, [high_demand, dispatch]);

  useEffect(() => {
    if (filteredProducts?.status === "idle") {
      dispatch(
        getRequiredData({
          lang: "en",
        })
      );
    }
  }, [filteredProducts, dispatch]);

  useEffect(() => {
    if (recently_added?.status === "idle") {
      dispatch(
        fetchProductByCategory({
          categoryId: "Recently Added",
          per_page: 10,
          page: 1,
          filter: "",
          name: "",
          isShowAll: false,
          isShowSold: false,
        })
      );
    }
  }, [recently_added, dispatch]);

  useEffect(() => {
    if (most_viewed?.status === "idle") {
      dispatch(
        fetchProductByCategory({
          categoryId: "Most Viewed",
          per_page: 10,
          page: 1,
          filter: "",
          name: "",
          isShowAll: false,
          isShowSold: false,
        })
      );
    }
  }, [most_viewed, dispatch]);

  useEffect(() => {
    if (recently_sold?.status === "idle") {
      dispatch(
        fetchProductByCategory({
          categoryId: "Recently Sold",
          per_page: 10,
          page: 1,
          filter: "",
          name: "",
          isShowAll: false,
          isShowSold: false,
        })
      );
    }
  }, [recently_sold, dispatch]);

  useEffect(() => {
    if (best_selling?.status === "idle") {
      dispatch(
        fetchProductByCategory({
          categoryId: "Best Selling",
          per_page: 10,
          page: 1,
          filter: "",
          name: "",
          isShowAll: false,
          isShowSold: true,
        })
      );
    }
  }, [best_selling, dispatch]);

  useEffect(() => {
    if (cart?.status === "idle") {
      dispatch(fetchMyCart({}));
    }
  }, [cart, dispatch]);

  useEffect(() => {
    if (cart?.status === "idle") {
      dispatch(getBannerImages({}));
    }
  }, [cart, dispatch]);


  

  // High Demand
  // Recently Added
  // Rochen Bold => accessory
  // most viewed
  // recently sold

  return (
    <div className={` bg-background800 no-scrollbar`}>
      <Hero />

      <NewInWatchSection
        header="High Demand"
        status={high_demand && high_demand?.status}
        products={high_demand?.status === "succeeded" && high_demand?.products}
      />
      <NewInWatchSection
        header="Recently Added"
        status={recently_added && recently_added?.status}
        products={
          recently_added?.status === "succeeded" && recently_added?.products
        }
      />
      <NewInWatchSection
        header="Most Viewed"
        status={most_viewed && most_viewed?.status}
        products={most_viewed?.status === "succeeded" && most_viewed?.products}
      />
      <NewInWatchSection
        header="Recently Sold"
        status={recently_sold && recently_sold?.status}
        products={
          recently_sold?.status === "succeeded" && recently_sold?.products
        }
      />
      <ExploreStore
        header="Store"
        status={high_demand && high_demand?.status}
        products={high_demand?.status === "succeeded" && high_demand?.products}
      />

      <NewInAccesoriesSection
        header="Accessory"
        status={best_selling && best_selling?.status}
        products={
          best_selling?.status === "succeeded" && best_selling?.products
        }
      />
      <OurApp />
      <WhyUs />
      <Footer />
    </div>
  );
}

export default Home;
