import React from "react";
import Chip from "./Chip";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAllCategoryProducts } from "../store/categorySlice";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import Button from "./Button";

const WatchCard = ({ watchDetail, page }) => {

  const navigation = useNavigate();
  const categoryProducts = useSelector(selectAllCategoryProducts);
  return (
    <div>
      {categoryProducts?.status === "loading" && page === 1 ? (
        <Card className="mt-6 w-[188px] h-[292px] mb-0 sm:mb-9 sm:w-[300px] sm:h-[465px] rounded-none sm:rounded-[10px] animate-pulse bg-gray-900">
          <CardHeader
            shadow={false}
            floated={false}
            className="w-[150px] h-[140px] sm:w-[270px] sm:h-[286px] object-cover rounded-none sm:rounded-t-[10px] relative grid place-items-center bg-gray-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-12 w-12 text-gray-900"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
              />
            </svg>
          </CardHeader>
          <CardBody>
            <Typography
              as="div"
              variant="h1"
              className="mb-4 h-3 w-38 sm:h-3 sm:w-56 rounded-full bg-gray-500"
            >
              &nbsp;
            </Typography>
            <Typography
              as="div"
              variant="paragraph"
              className="mb-2 h-2 w-full rounded-full bg-gray-500"
            >
              &nbsp;
            </Typography>
            <Typography
              as="div"
              variant="paragraph"
              className="mb-2 h-2 w-full rounded-full bg-gray-500"
            >
              &nbsp;
            </Typography>
            <Typography
              as="div"
              variant="paragraph"
              className="mb-2 h-2 w-full rounded-full bg-gray-500"
            >
              &nbsp;
            </Typography>
          </CardBody>
          <CardFooter className="pt-0">
            <Button
              disabled
              tabIndex={-1}
              className="h-8 w-20 bg-gray-500 shadow-none hover:shadow-none"
            >
              &nbsp;
            </Button>
          </CardFooter>
        </Card>
      ) : (
        <div>
          <div
            className={`w-[188px] max-h-[300px] mb-0 sm:mb-9 sm:w-[300px] sm:max-h-[465px] rounded-xl sm:rounded-[10px] cursor-pointer border border-[#000000]/[.30]`}
            style={{
              // borderRadius: '10px',
              background:
                "linear-gradient(136deg, #201D1D 50.15%, #181515 100%))",
              boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.15)",
            }}
            onClick={() =>
              navigation("/product-detail", {
                state: { watchDetail: watchDetail },
              })
            }
          >
            <div className="flex justify-center items-center basis-2/3">
              {
                <img
                  src={
                    watchDetail?.images?.length && watchDetail?.images[0]?.src
                  }
                  className="w-[186px] h-[150px] sm:w-[318px] sm:h-[286px] rounded-t-xl object-cover sm:rounded-t-[10px]"
                />
              }
            </div>
            <div className={`flex flex-col items-start p-4 gap-2 basis-1/3`}>
              <div className={`flex flex-col gap-[3px] `}>
                <span
                  className={`text-tiny text-xs sm:text-base text-white opacity-60`}
                >
                  {watchDetail?.name}
                </span>
                <span className={` text-haeder text-lg sm:text-2xl`}>
                  {watchDetail?.product_brand_id?.name}
                </span>
                {watchDetail?.product_type !== "accessory" && (
                  <span
                    className={`text-default opacity-60 text-white text-[10px] sm:text-xs `}
                  >
                    With {watchDetail?.bracelet_type_id?.name}
                  </span>
                )}
              </div>
              <div
                className={`flex flex-row justify-center items-center gap-2`}
              >
                <div
                  className={`flex justify-center items-end text-default opacity-60 text-white gap-1`}
                >
                  <span className={`text-[10px]`}>
                    {watchDetail?.product_currency?.code}
                  </span>
                  <span className={`text-xs sm:text-2xl`}>
                    {watchDetail?.price}
                  </span>
                </div>
                {watchDetail?.on_sale && (
                  <Chip
                    className="text-[10px]"
                    text={"Special Price"}
                    isBorder
                    textColor={"gold"}
                  />
                )}
              </div>
              <div
                className={`flex justify-start flex-wrap items-center gap-2`}
              >
                {watchDetail?.tags?.map((value, index) => {
                  return (
                    <Chip
                      key={index}
                      text={value}
                      textColor={"whiteSecondary"}
                    />
                  );
                })}
              </div>
              {/* <div className={`flex justify-start flex-col  `}>
                {watchDetail?.product_type === "accessory" && (
                  <>
                    <span className="text-tiny text-xs sm:text-base text-white opacity-60">
                      Seller
                    </span>
                    <span className="text-tiny text-xs sm:text-base text-white opacity-60">
                      {watchDetail?.seller?.name}
                    </span>
                  </>
                )}
              </div> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WatchCard;
