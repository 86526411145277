// Get Cart Details
// Update Cart Details

// Order

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../helpers/axios.js';

export const fetchMyCart = createAsyncThunk(
  'cart/fetchMyCart',
  async ({}) => {
    const response = await axiosInstance.post('/gmt/fetch_my_cart_v2', {}, {withCredentials: true, });
    return response.data.result;
  }
);

export const updateCart = createAsyncThunk(
    'user/updateCart',
    async ({product_id, add_qty=1}) => {
        console.log('========', product_id, add_qty)
      const response = await axiosInstance.post('/gmt/update_cart_json', {product_id, add_qty}, {withCredentials: true, });
      return response.data.result;
    }
);

const initialState = {
    status: 'idle',
    cartDetail: {},
    error: null,
    
};


const cartSlice = createSlice({
  name: 'cartDetails',
  initialState,
  reducers: {
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchMyCart.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMyCart.fulfilled, (state, action) => {
        state.status = 'succeeded';
        console.log('===action===', action)
        state.cartDetail = action.payload;
      })
      .addCase(fetchMyCart.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

    //   .addCase(updateCart.pending, (state) => {
    //     state.status = 'loading';
    //   })
    //   .addCase(updateCart.fulfilled, (state, action) => {
    //     state.status = 'succeeded';
    //     state.cartDetail = action.payload?.customers;
    //   })
    //   .addCase(updateCart.rejected, (state, action) => {
    //     state.status = 'failed';
    //     state.error = action.error.message;
    //   })
  },
});

// export const cartDetails = (state) => state.cartDetails;
export default cartSlice.reducer;