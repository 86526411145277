import { Chip, Spinner, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import Search from "../assets/icons/search.svg";
import Button from "../components/Button";
import CategoryBanner from "../components/CategoryBanner";
import Footer from "../components/Footer";
import WatchCard from "../components/WatchCard";
import { filterProductData, getRequiredData } from "../store/categorySlice";
import Select from "../components/Select";

import {
  fetchAllProducts,
  selectAllCategoryProducts,
} from "../store/categorySlice";
const per_page = 12;

function Category({product_type}) {
  const dispatch = useDispatch();
  const categoryProducts = useSelector(selectAllCategoryProducts);
  const [selectedItems, setSelectedItems] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const filteredProducts = useSelector(filterProductData);

  const handleToggleItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((selected) => selected !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };
  const handleSearch = (event) => {
    const inputValue = event.target.value;
    setSearch(inputValue);
    if (categoryProducts?.products) {
      const filteredResult = categoryProducts.products.filter((data) =>
        data.watch_category_id.name.toLowerCase().includes(search)
      );
      setFilteredData(filteredResult);
    }
  };

  const displayData =
    filteredData.length > 0 ? filteredData : categoryProducts?.products;
  const navigation = useNavigate();

  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(fetchAllProducts({ per_page: per_page, page: page}));
  }, [page]);

  useEffect(() => {
    if (filteredProducts?.status === "idle") {
      dispatch(
        getRequiredData({
          lang: "en",
        })
      );
    }
  }, [filteredProducts, dispatch]);
  const viewMore = () => setPage((page) => page + 1);
  return (
    <div>
      <div className={`bg-background800 no-scrollbar relative`}>
        <CategoryBanner />

        <div className="selected-items">
          {selectedItems.map((item) => (
            <span key={item} className="badge">
              {item}{" "}
              <button
                onClick={() => handleToggleItem(item)}
                className="badge-remove-button"
              >
                x
              </button>
            </span>
          ))}
        </div>
        <div className="flex flex-row items-center gap-8 mt-2">
          <div className="sm:flex sm:flex-row gap-2 pl-4 sm:pl-[42px]  grid grid-cols-2 overflow-x-scroll w-fit relative  no-scrollbar">
            {selectedFilters?.map((item, index) => (
              <Chip
                key={`${item.value}`}
                className="text-white w-auto h-10 gap-2   bg-background700 rounded-full"
                value={
                  <Typography className="capitalize leading-none text-base">
                    {item.label}
                  </Typography>
                }
                onClose={() => {
                  setSelectedFilters(
                    selectedFilters.filter(
                      (filter) =>
                        filter.value !== item.value ||
                        filter.filter !== item.filter
                    )
                  );
                }}
              />
            ))}
          </div>
          <div>
            {selectedFilters.length > 0 && (
              <span
                className=" sm:block hidden text-tiny text-default text-base text-gold"
                onClick={() => setSelectedFilters([])}
              >
                Clear Filters
              </span>
            )}
          </div>
        </div>

        <div
          className={`flex sm:block  overflow-x-scroll w-full no-scrollbar px-6`}
        >
          <div className="flex ">
            <Select
              text={"Brands"}
              options={filteredProducts?.products?.brands}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
            <Select
              text={"Watch Status"}
              options={filteredProducts?.products?.watch_status}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
            <Select
              text={"Bracelet"}
              options={filteredProducts?.products?.bracelettype}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
            <Select
              text={"Model year"}
              options={filteredProducts?.products?.model_year}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />

            <Select
              text={"Movement"}
              options={filteredProducts?.products?.movement}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          </div>
        </div>

        <div
          className={` grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 sm:gap-9 px-0 sm:px-[30px] justify-items-center items-center`}
        >
          {displayData &&
            displayData?.map((value, index) => {
              return (
                <div key={index}>
                  <WatchCard
                    watchDetail={value}
                    page={page}
                    onClick={() => navigation("/product-detail")}
                  />
                </div>
              );
            })}
        </div>
        {categoryProducts?.status === "loading" && (
          <div className="flex justify-items-center ">
            <Spinner className="h-4 w-full " />
          </div>
        )}
        {categoryProducts?.is_more && (
          <div className={`hidden sm:flex sm:justify-center pt-[52px]`}>
            <Button
              text={"View More"}
              isBorder={false}
              onClick={() => viewMore()}
              width={"w-[161px] md:w-[161px]"}
              height={"h-[40px] md:h-[40px]"}
            />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Category;
